import './App.css';
import './Gallery.css';
import { HomePage, AboutPage, CalendarPage } from './Pages.js'
import { GalleryPage } from './Gallery.js'
import {useState, useEffect} from 'react'

function NavBarButton ({id, content, onClick}) {
    return (
        <button onClick={() => onClick(id)} class="navbar-button" id={"navbar-" + {id}}>{content}</button>
    )
}


function NavBar ({onClick}) {
    return (
        <nav>
            <NavBarButton onClick={onClick} id="navbar_button_home" content="Home" />
            <NavBarButton onClick={onClick} id="navbar_button_about" content="About" />
            <NavBarButton onClick={onClick} id="navbar_button_calendar" content="Calendar" />
            <NavBarButton onClick={onClick} id="navbar_button_gallery" content="Gallery" />
        </nav>
    )
}


const pages = {
navbar_button_home: HomePage,
navbar_button_about: AboutPage,
navbar_button_calendar: CalendarPage,
navbar_button_gallery: GalleryPage,
}

function App() {
    const [pageContent, setPageContent] = useState("navbar_button_home");
    const [renderPage, setRenderPage] = useState(pages[pageContent])

    function clickTheButton (id) {
        setPageContent(id)
    }

    useEffect (() => {
        setRenderPage(pages[pageContent]);
    }, [pageContent])

  return (
    <div className="App">
      <header className="App-header">
        <NavBar onClick={clickTheButton} />
        <div class="page-content">{renderPage}</div>
      </header>
    </div>
  );
}

export default App;
