import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import "./Gallery.css"


export function GalleryPage() {
    function GalleryImage ({alt, src}) {
        let thumb="thumbs/"+src;
        src="images/"+src;
        return (
            <Zoom zoomImg={{ src: src, alt: alt }}>
                <img class="gallery-image" alt={alt} src={thumb} />
            </Zoom>
        )
    }
    return (
<div>
    <h1>Gallery</h1>
        <div class="gallery-section">
            <h2>The Listening Room</h2>

            <div class="gallery-grid">
                <GalleryImage alt="" src="listening-room/img_e9799.jpg" />
                <GalleryImage alt="" src="listening-room/img_5158.jpg" />
                <GalleryImage alt="" src="listening-room/img_5353.jpg" />
                <GalleryImage alt="" src="listening-room/img_5127.jpg" />
                <GalleryImage alt="" src="listening-room/img_9788.jpg" />
                <GalleryImage alt="" src="listening-room/img_5535.jpg" />
                <GalleryImage alt="" src="listening-room/20221020_190304.jpg" />
                <GalleryImage alt="" src="listening-room/img_9796.jpg" />
                <GalleryImage alt="" src="listening-room/img_e5517.jpg" />
                <GalleryImage alt="" src="listening-room/img_5345.jpg" />
                <GalleryImage alt="" src="listening-room/img_5157.jpg" />
                <GalleryImage alt="" src="listening-room/img_9372.jpg" />
                <GalleryImage alt="" src="listening-room/img_5126.jpg" />
                <GalleryImage alt="" src="listening-room/img_5521.jpg" />
                <GalleryImage alt="" src="listening-room/img_9338.jpg" />
                <GalleryImage alt="" src="listening-room/img_5470.jpg" />
                <GalleryImage alt="" src="listening-room/20221020_203127.jpg" />
                <GalleryImage alt="" src="listening-room/img_5355.jpg" />
                <GalleryImage alt="" src="listening-room/img_5153.jpg" />
                <GalleryImage alt="" src="listening-room/20221027_192347.jpg" />
                <GalleryImage alt="" src="listening-room/img_5524.jpg" />
                <GalleryImage alt="" src="listening-room/img_5543.jpg" />
                <GalleryImage alt="" src="listening-room/20221019_195924.jpg" />
                <GalleryImage alt="" src="listening-room/img_5119.jpg" />
                <GalleryImage alt="" src="listening-room/img_5230.jpg" />
                <GalleryImage alt="" src="listening-room/img_e9373.jpg" />
                <GalleryImage alt="" src="listening-room/img_5522.jpg" />
                <GalleryImage alt="" src="listening-room/img_5350.jpg" />
                <GalleryImage alt="" src="listening-room/img_4931.jpg" />
                <GalleryImage alt="" src="listening-room/img_5121.jpg" />
                <GalleryImage alt="" src="listening-room/img_5525.jpg" />
                <GalleryImage alt="" src="listening-room/img_5352.jpg" />
                <GalleryImage alt="" src="listening-room/img_9795.jpg" />
                <GalleryImage alt="" src="listening-room/20221019_195934.jpg" />
                <GalleryImage alt="" src="listening-room/img_5530.jpg" />
                <GalleryImage alt="" src="listening-room/20221020_195338.jpg" />
                <GalleryImage alt="" src="listening-room/20221027_201752.jpg" />
                <GalleryImage alt="" src="listening-room/20221020_201013.jpg" />
                <GalleryImage alt="" src="listening-room/img_5156.jpg" />
                <GalleryImage alt="" src="listening-room/img_5523.jpg" />
                <GalleryImage alt="" src="listening-room/img_9791.jpg" />
                <GalleryImage alt="" src="listening-room/img_5162.jpg" />
                <GalleryImage alt="" src="listening-room/img_5532.jpg" />
                <GalleryImage alt="" src="listening-room/img_5537.jpg" />
                <GalleryImage alt="" src="listening-room/20221019_202536.jpg" />
                <GalleryImage alt="" src="listening-room/img_5533.jpg" />
                <GalleryImage alt="" src="listening-room/img_5164.jpg" />
                <GalleryImage alt="" src="listening-room/img_5550.jpg" />
                <GalleryImage alt="" src="listening-room/img_5161.jpg" />
                <GalleryImage alt="" src="listening-room/img_4926.jpg" />
                <GalleryImage alt="" src="listening-room/20221027_194907.jpg" />
                <GalleryImage alt="" src="listening-room/img_4928.jpg" />
                <GalleryImage alt="" src="listening-room/img_5551.jpg" />
                <GalleryImage alt="" src="listening-room/img_5122.jpg" />
                <GalleryImage alt="" src="listening-room/img_5555.jpg" />
                <GalleryImage alt="" src="listening-room/img_5232.jpg" />
                <GalleryImage alt="" src="listening-room/img_5155.jpg" />
                <GalleryImage alt="" src="listening-room/img_4932.jpg" />
                <GalleryImage alt="" src="listening-room/img_5536.jpg" />
                <GalleryImage alt="" src="listening-room/img_5124.jpg" />
                <GalleryImage alt="" src="listening-room/img_9373.jpg" />
                <GalleryImage alt="" src="listening-room/img_5467.jpg" />
                <GalleryImage alt="" src="listening-room/20221027_203240.jpg" />
                <GalleryImage alt="" src="listening-room/img_5120.jpg" />
                <GalleryImage alt="" src="listening-room/img_4933.jpg" />
                <GalleryImage alt="" src="listening-room/img_5526.jpg" />
                <GalleryImage alt="" src="listening-room/img_e5594.jpg" />
                <GalleryImage alt="" src="listening-room/img_5520.jpg" />
                <GalleryImage alt="" src="listening-room/img_9371.jpg" />
                <GalleryImage alt="" src="listening-room/20221027_205538.jpg" />
                <GalleryImage alt="" src="listening-room/img_9792.jpg" />
                <GalleryImage alt="" src="listening-room/img_4927.jpg" />
                <GalleryImage alt="" src="listening-room/img_5154.jpg" />
                <GalleryImage alt="" src="listening-room/img_4929.jpg" />
                <GalleryImage alt="" src="listening-room/img_5252.jpg" />
                <GalleryImage alt="" src="listening-room/img_5351.jpg" />
                <GalleryImage alt="" src="listening-room/img_9339.jpg" />
                <GalleryImage alt="" src="listening-room/img_9793.jpg" />
                <GalleryImage alt="" src="listening-room/20221019_204734.jpg" />
                <GalleryImage alt="" src="listening-room/img_9794.jpg" />
                <GalleryImage alt="" src="listening-room/img_5160.jpg" />
                <GalleryImage alt="" src="listening-room/img_5349.jpg" />
                <GalleryImage alt="" src="listening-room/20221019_203328.jpg" />
                <GalleryImage alt="" src="listening-room/20221019_204518.jpg" />
                <GalleryImage alt="" src="listening-room/img_5531.jpg" />
                <GalleryImage alt="" src="listening-room/img_5347.jpg" />
                <GalleryImage alt="" src="listening-room/img_9789.jpg" />
                <GalleryImage alt="" src="listening-room/img_5233.jpg" />
                <GalleryImage alt="" src="listening-room/img_5356.jpg" />
                <GalleryImage alt="" src="listening-room/img_9790.jpg" />
                <GalleryImage alt="" src="listening-room/img_5163.jpg" />
                <GalleryImage alt="" src="listening-room/20221027_200336.jpg" />
                <GalleryImage alt="" src="listening-room/img_4934.jpg" />
                <GalleryImage alt="" src="listening-room/img_5529.jpg" />
                <GalleryImage alt="" src="listening-room/img_5229.jpg" />
                <GalleryImage alt="" src="listening-room/img_9370.jpg" />
                <GalleryImage alt="" src="listening-room/20221020_190159.jpg" />
                <GalleryImage alt="" src="listening-room/img_5231.jpg" />
                <GalleryImage alt="" src="listening-room/img_5348.jpg" />
            </div>

            <div class="gallery-section">
                <h2>Old Bands</h2>
                <h3>The Aardvarks</h3>
                <div class="gallery-grid">
                    <GalleryImage alt="" src="aardvaarks/10.jpg" />
                    <GalleryImage alt="" src="aardvaarks/11.jpg" />
                    <GalleryImage alt="" src="aardvaarks/12.jpg" />
                    <GalleryImage alt="" src="aardvaarks/13.jpg" />
                    <GalleryImage alt="" src="aardvaarks/14.jpg" />
                    <GalleryImage alt="" src="aardvaarks/15.jpg" />
                    <GalleryImage alt="" src="aardvaarks/16.jpg" />
                    <GalleryImage alt="" src="aardvaarks/17.jpg" />
                    <GalleryImage alt="" src="aardvaarks/2.jpg" />
                    <GalleryImage alt="" src="aardvaarks/3.jpg" />
                    <GalleryImage alt="" src="aardvaarks/4.jpg" />
                    <GalleryImage alt="" src="aardvaarks/5.jpg" />
                    <GalleryImage alt="" src="aardvaarks/6.jpg" />
                    <GalleryImage alt="" src="aardvaarks/7.jpg" />
                    <GalleryImage alt="" src="aardvaarks/8.jpg" />
                    <GalleryImage alt="" src="aardvaarks/9.jpg" />
                </div>
                <h3>Arthur and the Blue Knights</h3>
                <div class="gallery-grid">
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/237210ce-994e-49a7-993a-2240b3986d41.jpg" />
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/4a1f0eb9-e4ae-4011-ae6f-badaa35dda42.jpg" />
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/534e3ab4-58b5-42ce-b4d7-1cf314d8945d.jpg" />
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/790ab3d0-caff-41e2-8593-164748fd673d.jpg" />
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/a4bba5c1-47dd-45f2-b471-acfff97f2e60.jpg" />
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/a83d576c-d0d3-4a2f-8528-bdd8eab4e0c2.jpg" />
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/c46c70e3-82fe-4eac-94af-7b138681e5bb.jpg" />
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/d9057159-4a28-40f6-8399-24f7ac157cce.jpg" />
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/e0712120-6182-4ab7-bcef-e2d99b9ffa4b.jpg" />
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/e62025ce-bb04-4a02-9018-3b66914d4bca.jpg" />
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/e6257ae2-407d-4b00-99b9-27207a3bbb70.jpg" />
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/ed800f6e-8b4d-4276-8564-9ba2a20c4310.jpg" />
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/f160d175-607f-4c51-b0b1-b62cfbee2574.jpg" />
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/f484bda4-9710-48fa-aa4b-685c476deec3.jpg" />
                    <GalleryImage alt="" src="arthur-and-the-blue-knights/f7dfa3cb-be35-413a-943c-1b093dbd455c.jpg" />
                </div>
                <h3>Black Label</h3>
                <div class="gallery-grid">
                    <GalleryImage alt="" src="black-label/54ed9da0-4b0f-4638-97e0-b94a78dc1efe.jpg" />
                    <GalleryImage alt="" src="black-label/bf2e1f38-6aad-4340-9f3b-b13a4cdd4154.jpg" />
                    <GalleryImage alt="" src="black-label/cf9bcf8c-a1ee-49df-9ef1-9f7dada82740.jpg" />
                    <GalleryImage alt="" src="black-label/f39fefa0-bced-4d82-8768-fe88cc0b9a00.jpg" />
                </div>
                <h3>Misc</h3>
                <div class="gallery-grid">
                    <GalleryImage alt="" src="misc/046e572c-9b2c-41f9-915d-63b6a3dbdc7a.jpg" />
                    <GalleryImage alt="" src="misc/1387a4a7-f06e-46b6-86e1-68b1b18aa227.jpg" />
                    <GalleryImage alt="" src="misc/1b2b0b1e-dbaf-4db1-a8ac-a110f9840419.jpg" />
                    <GalleryImage alt="" src="misc/2c196ab4-4c25-4982-9a0e-0543fbf20958.jpg" />
                    <GalleryImage alt="" src="misc/302726cf-deeb-42de-bc2e-f7a393bd96b6.jpg" />
                    <GalleryImage alt="" src="misc/53dc3dca-7c36-40f2-8f5d-9e58d5ec6009.jpg" />
                    <GalleryImage alt="" src="misc/5c20af6f-5999-40dd-a4df-32be6ae9a23e.jpg" />
                    <GalleryImage alt="" src="misc/677831d7-61b9-49a6-a43d-b4044edc525b.jpg" />
                    <GalleryImage alt="" src="misc/76d427ca-c462-4691-8bd0-d08fdf4c683a.jpg" />
                    <GalleryImage alt="" src="misc/77432269-3131-48f9-ada5-2c58d68f2612.jpg" />
                    <GalleryImage alt="" src="misc/88b161e1-1d45-4c47-a2fd-1216f5e6b2a3.jpg" />
                    <GalleryImage alt="" src="misc/89ce18e1-1ca4-46ed-98c6-71628328ee5c.jpg" />
                    <GalleryImage alt="" src="misc/9b524621-5c6f-43a7-91a1-c0d704573c58.jpg" />
                    <GalleryImage alt="" src="misc/d0a4d7ae-b4ab-4660-a75f-25089cf279ae.jpg" />
                    <GalleryImage alt="" src="misc/f1cde079-187f-42b2-8042-c04ee7f8bfe6.jpg" />
                </div>
                <h3>Rough Diamond</h3>
                <div class="gallery-grid">
                    <GalleryImage alt="" src="rough-diamond/119059da-18fa-4c43-b360-c72ca6761b0c.jpg" />
                    <GalleryImage alt="" src="rough-diamond/18ed8a28-b78d-4aab-96ba-a6db7afda611.jpg" />
                    <GalleryImage alt="" src="rough-diamond/1a5c1bf2-f589-4e6e-b71d-82bcd309b6a3.jpg" />
                    <GalleryImage alt="" src="rough-diamond/1a5dabe7-1dac-49b1-a252-bab491545cac.jpg" />
                    <GalleryImage alt="" src="rough-diamond/1a7feba5-2933-4ce8-bcd4-478286eec74f.jpg" />
                    <GalleryImage alt="" src="rough-diamond/2335123f-6b8d-425c-b553-332292aecfda.jpg" />
                    <GalleryImage alt="" src="rough-diamond/331e7e0f-6e1c-4e98-b29c-0317c33d8766.jpg" />
                    <GalleryImage alt="" src="rough-diamond/33becb86-e37f-458b-8de4-ce478f037abf.jpg" />
                    <GalleryImage alt="" src="rough-diamond/35e49c2a-7d8d-45b6-81f4-ce0f56922972.jpg" />
                    <GalleryImage alt="" src="rough-diamond/3a13645d-af4a-4e18-843d-df46e74463f5.jpg" />
                    <GalleryImage alt="" src="rough-diamond/3dc7ad22-38e4-4bed-a09f-f4fab98c7b8a.jpg" />
                    <GalleryImage alt="" src="rough-diamond/46cc9a31-3f76-4ab6-995e-65c683ce518c.jpg" />
                    <GalleryImage alt="" src="rough-diamond/4a2d9622-1daf-4494-81c4-7c717c1fd29f.jpg" />
                    <GalleryImage alt="" src="rough-diamond/4b8970c8-3608-42d2-809e-78d406ce3859.jpg" />
                    <GalleryImage alt="" src="rough-diamond/4e0148d8-0f66-441d-aebc-ae5aee35c710.jpg" />
                    <GalleryImage alt="" src="rough-diamond/53030059-1d7b-4200-9acf-69c17599d373.jpg" />
                    <GalleryImage alt="" src="rough-diamond/5762ebda-6f4c-4ee3-ba9d-80e099ee9b8a.jpg" />
                    <GalleryImage alt="" src="rough-diamond/60f07e85-9de4-4563-bf08-9a7429c353ce.jpg" />
                    <GalleryImage alt="" src="rough-diamond/63dbb8c5-ef83-420d-b5ef-263831d67e19.jpg" />
                    <GalleryImage alt="" src="rough-diamond/67fff154-4591-429b-a1cf-239b13cac8bc.jpg" />
                    <GalleryImage alt="" src="rough-diamond/77c2b8bf-f353-471c-940c-837c8cf162af.jpg" />
                    <GalleryImage alt="" src="rough-diamond/78cd7149-a115-4cb1-9233-4edfeeff2c4a.jpg" />
                    <GalleryImage alt="" src="rough-diamond/7ba1355d-110e-4402-afee-7da4e5f417e0.jpg" />
                    <GalleryImage alt="" src="rough-diamond/822ca142-2c41-4df5-994f-c64cee0f0e42.jpg" />
                    <GalleryImage alt="" src="rough-diamond/94bc419c-f788-4fd2-bef8-5857f62394dc.jpg" />
                    <GalleryImage alt="" src="rough-diamond/97fa0748-d373-4f2d-88f8-09a925a77806.jpg" />
                    <GalleryImage alt="" src="rough-diamond/a6fcfdd6-80aa-42f4-93bc-2b4f2c7ab500.jpg" />
                    <GalleryImage alt="" src="rough-diamond/aad67564-4d36-4ec1-b950-23bb6b4bb77a.jpg" />
                    <GalleryImage alt="" src="rough-diamond/ac6ddc96-0cd6-406f-ac1a-6cd1e4263ffb.jpg" />
                    <GalleryImage alt="" src="rough-diamond/ae342d1c-64e1-4134-9f89-f1ca43f89d43.jpg" />
                    <GalleryImage alt="" src="rough-diamond/ae961fa7-f42c-44f3-8889-d1ed1972cbf4.jpg" />
                    <GalleryImage alt="" src="rough-diamond/b2fa3463-cc99-4385-a7d1-6c9c786d16e8.jpg" />
                    <GalleryImage alt="" src="rough-diamond/b3179240-3deb-4acd-a4b1-82a9c1adab49.jpg" />
                    <GalleryImage alt="" src="rough-diamond/c48cf583-73e0-453c-8504-d98c17c34d19.jpg" />
                    <GalleryImage alt="" src="rough-diamond/c556b7aa-0293-4bb9-aa24-a2af1b771b34.jpg" />
                    <GalleryImage alt="" src="rough-diamond/cf8b715e-8d6f-423d-ab6b-5ff89bfc1dbe.jpg" />
                    <GalleryImage alt="" src="rough-diamond/d0c14258-9357-4d62-a6c1-5ba774635fcd.jpg" />
                    <GalleryImage alt="" src="rough-diamond/e14104be-006f-4e1b-864d-0c5afd128144.jpg" />
                    <GalleryImage alt="" src="rough-diamond/e8f68741-216d-497f-aa6c-a88cf54dc4df.jpg" />
                    <GalleryImage alt="" src="rough-diamond/f393ed37-30f5-4306-a7d4-526d6dacb087.jpg" />
                    <GalleryImage alt="" src="rough-diamond/ff96a159-6a7e-402d-a540-b4b742fe00e9.jpg" />
                </div>
                <h3>The Buzzards</h3>
                <div class="gallery-grid">
                    <GalleryImage alt="" src="the-buzzards/16277aac-20f4-4b45-9ce4-a7f6f770abd4.jpg" />
                    <GalleryImage alt="" src="the-buzzards/19e94931-d61d-4de7-b795-ee5cb0d14637.jpg" />
                    <GalleryImage alt="" src="the-buzzards/32fded50-84e2-4b04-a3b7-ec07a9d85042.jpg" />
                    <GalleryImage alt="" src="the-buzzards/3fbf76a5-77a6-4ce5-935f-8155159169e0.jpg" />
                    <GalleryImage alt="" src="the-buzzards/49b14c25-5725-445a-94f4-de4584cec922.jpg" />
                    <GalleryImage alt="" src="the-buzzards/87f3ddf4-ef33-4f99-8e03-15eda73468f7.jpg" />
                    <GalleryImage alt="" src="the-buzzards/8f859e7f-7e93-4bde-b54c-a02a0d0e16ac.jpg" />
                    <GalleryImage alt="" src="the-buzzards/9ae61f14-fae4-4237-b308-bc53ce7b11e7.jpg" />
                    <GalleryImage alt="" src="the-buzzards/dd26edfb-b818-4967-9cc8-06d19a4245d6.jpg" />
                </div>
                <h3>The Cauze</h3>
                <div class="gallery-grid">
                    <GalleryImage alt="" src="the-cauze/77d6f8a7-f566-430d-b42f-c92e3d63476b.jpg" />
                    <GalleryImage alt="" src="the-cauze/9f03d7be-24a3-4320-887f-d0f6407050b4.jpg" />
                </div>
            </div>
    </div>
</div>
    )
}
