import FullCalendar from '@fullcalendar/react'
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!


export function AboutPage () {
    return (
        <div class="main">
        <h1>About</h1>
        <img class="front-page-art" src="/images/about/about-art.jpg" alt="Arthur." />
        <h2>About Art</h2>
        <p class="about-text">Arthur has played music for around 40 years as can be seen from the photos on this website. 
        Currently running an open mic night each week and also performing at Bathurst venues. 
        Arthur also teaches guitar, Bass and Drums at Scotts All Saints School in Bathurst</p>
        <h2>Contact</h2>
        <p>
        For information and available dates , Arthur can be contacted on 0428 323 121 or. emailer at AGS.music@outlook.com or Arthur.sonter@hotmail.com</p>
        </div>
    )
}

export function HomePage () {
    return (
        <div>
        <div class="main">
        <h1>Arthur Sonter Music</h1>
        <p class="subheading">Arthur has been playing music in bands semi professionally since 1982.
        Currently running an open mic night in Bathurst each week as well as local gigs. Currently with a modern country band.
        </p>
        <img class="front-page-art" src="/images/home/home-art.jpg" alt="Arthur." />

        <p>In the 40 years that Arthur has been playing he has played in many different styles and genres, from country to rock to blues and even musicals.
        He is immensely proud of his musical journey over that many years .
        He also has a passion for guitars and guitar amplifiers
        </p>
        </div>

        <h2>Get In Touch</h2>

        <div class="socials">

        <a href="mailto:arthur.sonter@hotmail.com">
        <div class="social-icon">
        <i class="icon fa-solid fa-envelope"></i>
        <p class="socials-text">arthur.sonter<wbr />@hotmail.com</p>
        </div>
        </a>

        <a href="mailto:AGS.music@outlook.com">
        <div class="social-icon">
        <i class="icon fa-solid fa-envelope"></i>
        <p class="socials-text">AGS.music<wbr />@outlook.com</p>
        </div>
        </a>

        <a href="https://www.facebook.com/ListeningRoomBx/">
        <div class="social-icon">
        <i class="icon fa-brands fa-facebook-f"></i>
        <p class="socials-text">The Listening Room</p>
        </div>
        </a>

        <a href="https://www.facebook.com/AGSonter">
        <div class="social-icon">
        <i class="icon fa-brands fa-facebook-f"></i>
        <p class="socials-text">Arthur Sonter Music</p>
        </div>
        </a>

        <a href="tel:0428323121">
        <div class="social-icon">
        <i class="icon fa-solid fa-phone"></i>
        <p class="socials-text">0428 323 121</p>
        </div>
        </a>

        </div>
        </div>
    )
}

export function CalendarPage() {                                                                                          
    function renderEventContent (eventInfo) {
    return (
        <div >
          <div ><em>{eventInfo.timeText}m:</em></div>
          <div >{eventInfo.event.title}</div>
        </div>
    );
  };
    const settings = {                                                                                                          
        plugins: [                                                                                                                
            dayGridPlugin,                                                                                                          
            googleCalendarPlugin                                                                                                    
        ],                                                                                                                        
        googleCalendarApiKey:"AIzaSyBtP9Bo8Z8lDNvHMosTlbgpYjwTpv-Cgno",                                                          
        eventSources: [                                                                                                           
            {                                                                                                                       
                googleCalendarId:
                "3e01ff6c876ba57a89755daa53b191cef0b34140f2c881b1c27a867931e97a18@group.calendar.google.com",                                                             
            },                                                                                                                      
        ],                                                                                                                        
        eventContent: renderEventContent,
        initialView: "dayGridMonth",                                                                                              
    };                                                                                                                          

    return (                                                                                                                    
        <div>                                                                                                                        
        <h1>Calendar</h1>
        <FullCalendar class="calendar-widget" {...settings} />                                                                                          
        </div>                                                                                                                       
    );                                                                                                                          
}    

